<div id="footer-container" class="container-fluid p-0 mt-auto">
  <!-- Link assistenza -->
  <a id="payer-assistenza" href="https://www.lepida.net/assistenza/richiesta-assistenza-payer" target="_blank">
    <svg class="icon icon-sm">
      <use xlink:href="assets/icons/bootstrap-italia-icons.svg#it-pencil"></use>
    </svg>
    Hai un quesito o una segnalazione sul contenuto del sito?
  </a>
  <!-- FOOTER LEPIDA -->
  <footer class="py-0 text-light-footer bg-dark-footer ft-low-band">
    <div class="container-fluid footer-top">
      <div class="container footer-narrow-top">
        <div class="row justify-content-center">
          <div id="inside-footer-sinistra" class="col-md-12 col-lg-3 d-flex justify-content-left align-items-top">
            <h2 class="visually-hidden">Logo azienda nel footer</h2>
            <div class="region-footer-sinistra">
              <div id="block-footerlogo-2" class="contextual-region">
                <div class="clearfix">
                  <div class="footer-logo-top">
                    <a href="https://www.lepida.net" title="vai al sito di Lepida ScpA - apre una nuova pagina"
                      class="lepida-logo d-block" target="_blank">
                      <img src="/assets/img/logo_footer_bianco_new.svg" alt="logo Lepida ScpA" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="inside-footer-centro" class="col-md-12 col-lg-6 d-flex justify-content-center align-items-top">
            <h2 class="visually-hidden">Contatti azienda nel footer</h2>
            <div class="region-footer-centro">
              <div id="block-contatti" class="contextual-region">
                <div class="clearfix">
                  <p class="footer-contatti text-center">Lepida S.c.p.A. | Via della Liberazione 15, 40128 Bologna</p>
                  <p class="footer-contatti text-center">Capitale Sociale i.v. ad oggi &euro; 69.881.000,00 | PI/CF
                    02770891204</p>
                </div>
              </div>
            </div>
          </div>
          <div id="inside-footer-destra" class="col-md-12 col-lg-3 d-flex justify-content-right align-items-top">
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid footer-bottom">
      <div class="container footer-narrow-bottom">
        <div class="row justify-content-center">
          <div id="post-footer-sinistra" class="col-md-12 col-lg-2 d-flex justify-content-left align-items-bottom">
            <h2 class="visually-hidden">Logo azienda nel post footer</h2>
            <div class="region-post-footer-sinistra">
              <div id="block-footerlogo" class="contextual-region">
                <div class="clearfix">
                  <div class="footer-logo-left">
                    <a href="https://www.lepida.net" title="vai al sito di Lepida ScpA - apre una nuova pagina"
                      class="lepida-logo d-block" target="_blank">
                      <img src="/assets/img/logo_footer_bianco_new.svg" alt="logo Lepida ScpA" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="post-footer-centro" class="col-md-12 col-lg-8 d-flex justify-content-center align-items-bottom">
            <h2 class="visually-hidden">Menu dichiarazioni nel post footer</h2>
            <div class="region-post-footer-centro">
              <nav aria-labelledby="block-subfooter-menu" id="block-subfooter" class="contextual-region">
                <h3 class="visually-hidden" id="block-subfooter-menu">Subfooter</h3>
                <ul class="menu-subfooter mb-0 p-0">
                  <!-- <li class="menu-item btn-subfooter btn-subfooter-link">
                    <a href="/accessibilita"
                      title="apri la dichiarazione di Accessibilit&agrave; per questo sito">Accessibilit&agrave;</a>
                  </li> -->
                  <li class="menu-item btn-subfooter btn-subfooter-link">
                    <a href="/cookie-policy" title="apri l&#039;informativa Cookie per questo sito">Cookie policy</a>
                  </li>
                  <li class="menu-item btn-subfooter btn-subfooter-link">
                    <a href="/privacy" title="apri l&#039;informativa Privacy per questo sito">Privacy policy</a>
                  </li>
                  <li class="menu-item btn-subfooter btn-subfooter-link">
                    <a href="https://form.agid.gov.it/view/c590e950-76a3-11ef-ad7c-457e7fccc44c" target="_blank" title="apri la dichiarazione di accessibilità per questo sito">Dichiarazione di accessibilit&agrave;</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div id="post-footer-destra" class="col-md-12 col-lg-2 d-flex justify-content-right align-items-bottom">
            <h2 class="visually-hidden">Copyright nel post footer</h2>
            <div class="region-post-footer-destra">
              <div id="block-copyright" class="contextual-region">
                <div class="clearfix">
                  <p class="footer-copyright">&copy; Tutti i diritti riservati <a href="https://www.lepida.net"
                      title="vai al sito di Lepida ScpA - apre una nuova pagina" target="_blank">Lepida S.c.p.A.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- FOOTER LEPIDA -->
</div>
