import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { UserIdleModule } from "angular-user-idle";
import { DesignAngularKitModule } from "design-angular-kit";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { DpDatePickerModule } from "ng2-date-picker";
import { NgxCurrencyModule } from "ngx-currency";
import { CustomFormsModule } from "ngx-custom-validators";
import { AccordionModule } from "primeng/accordion";
import { ConfirmationService } from "primeng/api";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { ToolbarModule } from "primeng/toolbar";
import { TreeTableModule } from "primeng/treetable";
import { configUserIdle } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BannerComponent } from "./components/banner/banner.component";
import { CommonComponentModule } from "./components/common-component.module";
import { OverlayComponent } from "./components/overlay-dati-pagamento/overlay.component";
import { PayerCoreModule } from "./core/core.module";
import { ModaliGestisciFlussiRendicontazioneModule } from "./features/gestisci-anagrafiche/gestisci-flussi-rendicontazione/modali-gestisci-flussi-rendicontazione.module";
import { ModaliGestisciTipologiaServizioModule } from "./features/gestisci-anagrafiche/gestisci-tipologia-servizio/modali-gestisci-tipologia-servizio.module";
import { PagamentiModule } from "./features/pagamenti/pagamenti.module";
import { PipeModule } from "./pipe/pipe.module";
import { PayerUtilityPagesModule } from "./utility/pages/payer-utility-pages.module";

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    OverlayComponent,
  ],
  imports: [
    PayerCoreModule,
    PagamentiModule,
    PayerUtilityPagesModule,

    ModaliGestisciTipologiaServizioModule,
    ModaliGestisciFlussiRendicontazioneModule,

    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,

    ReactiveFormsModule,
    DesignAngularKitModule,
    NgxDatatableModule,
    AccordionModule,
    TableModule,
    ButtonModule,
    BrowserAnimationsModule,
    TabViewModule,
    ToolbarModule,
    DpDatePickerModule,
    NgxCurrencyModule,
    CustomFormsModule,
    ConfirmDialogModule,
    BrowserAnimationsModule,
    CustomFormsModule,
    AutoCompleteModule,
    TreeTableModule,
    PipeModule,
    CommonComponentModule,
    NgMultiSelectDropDownModule,

    UserIdleModule.forRoot(configUserIdle),
  ],
  exports: [UserIdleModule],
  providers: [ConfirmationService],
  bootstrap: [AppComponent],
})
export class AppModule {}
