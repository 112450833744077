import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DesignAngularKitModule } from "design-angular-kit";
import { DpDatePickerModule } from "ng2-date-picker";
import { NgxCurrencyModule } from "ngx-currency";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { CommonComponentModule } from "src/app/components/common-component.module";
import { PipeModule } from "src/app/pipe/pipe.module";
import { CarrelloComponent } from "./raw/carrello/carrello.component";
import { CarrelloL1Component } from "./raw/carrelloL1/carrello-l1.component";
import { FiltriIMieiPagamentiComponent } from "./raw/i-miei-pagamenti/filtri-i-miei-pagamenti/filtri-i-miei-pagamenti.component";
import { IMieiPagamentiComponent } from "./raw/i-miei-pagamenti/i-miei-pagamenti.component";
import { ListaPagamentiComponent } from "./raw/lista-pagamenti/lista-pagamenti.component";
import { ListaPagamentiL1Component } from "./raw/lista-pagamentiL1/lista-pagamenti-l1.component";
import { CompilaNuovoPagamentoComponent } from "./raw/nuovo-pagamento/compila-nuovo-pagamento/compila-nuovo-pagamento.component";
import { DatiNuovoPagamentoComponent } from "./raw/nuovo-pagamento/dati-nuovo-pagamento/dati-nuovo-pagamento.component";
import { NuovoPagamentoComponent } from "./raw/nuovo-pagamento/nuovo-pagamento.component";
import { PresaincaricopagamentoComponent } from "./raw/presaincaricopagamento/presaincaricopagamento.component";
import { WaitingComponent } from "./raw/waiting/waiting.component";
import { WaitingL1Component } from "./raw/waitingL1/waiting-l1.component";
import { NgbDropdownModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    NuovoPagamentoComponent,
    IMieiPagamentiComponent,
    CompilaNuovoPagamentoComponent,
    DatiNuovoPagamentoComponent,
    CarrelloComponent,
    CarrelloL1Component,
    PresaincaricopagamentoComponent,
    ListaPagamentiComponent,
    ListaPagamentiL1Component,
    WaitingComponent,
    WaitingL1Component,
    FiltriIMieiPagamentiComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DesignAngularKitModule,
    CommonComponentModule,
    ConfirmDialogModule,
    DpDatePickerModule,
    NgxCurrencyModule,
    PipeModule,
    NgbPaginationModule,
    NgbDropdownModule
  ],
  exports: [
    NuovoPagamentoComponent
  ],
})
export class PagamentiModule {}
