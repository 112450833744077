import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {SpinnerOverlayComponent} from "../../components/spinner-overlay/spinner-overlay.component";
import {defer, NEVER} from "rxjs";
import {finalize, share} from "rxjs/operators";

declare var jQuery: any;
declare var paperDashboard: any;

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  private overlayRef: OverlayRef = undefined;

  constructor(private overlay: Overlay) {}

  public readonly spinner$ = defer(() => {
    this.show();
    return NEVER.pipe(
      finalize(() => {
        this.hide();
      })
    );
  }).pipe(share());

  public show(): void {
    // Hack avoiding `ExpressionChangedAfterItHasBeenCheckedError` error
    Promise.resolve(null).then(() => {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically(),
        hasBackdrop: true,
      });
      this.overlayRef.attach(new ComponentPortal(SpinnerOverlayComponent));
    });
  }

  public hide(): void {
    this.overlayRef.detach();
    this.overlayRef = undefined;
    (function ($) {
      $(document).ready(function(){
        try {
          $("html").removeClass("nav-open");
          paperDashboard.misc.navbar_menu_visible = 0;
          setTimeout(function() {
            $(".navbar-toggle").removeClass("toggled"), $("#bodyClick").remove()
          }, 550);
        } catch (e) {

        }
      });
    })(jQuery);
  }
}
