import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { DesignAngularKitModule } from "design-angular-kit";
import { DpDatePickerModule } from "ng2-date-picker";
import { NgxCurrencyModule } from "ngx-currency";
import { CustomFormsModule } from "ngx-custom-validators";
import { AccordionModule } from "primeng/accordion";
import { ConfirmationService } from "primeng/api";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { ToolbarModule } from "primeng/toolbar";
import { TreeTableModule } from "primeng/treetable";
import { PayerErrorHandler } from "../core/errors/error-handler";
import { PipeModule } from "../pipe/pipe.module";
import { BackendInterceptor } from "../core/interceptors/backend.interceptor";
import { JwtInterceptor } from "../core/interceptors/jwt.interceptor";
import { UrlBackInterceptor } from "../core/interceptors/url-back.interceptor";
import { DayInputDirective } from "../utility/DayInputDirective";
import { IntegerNumberDirective } from "../utility/IntegerNumberDirective";
import { MonthInputDirective } from "../utility/MonthInputDirective";
import { AvvisoCampiObbligatoriComponent } from "./avviso-campi-obbligatori/avviso-campi-obbligatori.component";
import { SpinnerOverlayComponent } from "./spinner-overlay/spinner-overlay.component";
import { TabViewComponent } from "./tab-view/tab-view.component";
import { PaginatorComponent } from "./table/paginator/paginator.component";
import { TableComponent } from "./table/table.component";
import { ToolbarComponent } from "./toolbar/toolbar.component";

@NgModule({
  declarations: [
    TableComponent,
    IntegerNumberDirective,
    DayInputDirective,
    MonthInputDirective,
    SpinnerOverlayComponent,
    PaginatorComponent,
    TabViewComponent,
    ToolbarComponent,
    AvvisoCampiObbligatoriComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    NgbModule,
    FormsModule,
    DesignAngularKitModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    AccordionModule,
    TableModule,
    ButtonModule,
    TabViewModule,
    ToolbarModule,
    DpDatePickerModule,
    NgxCurrencyModule,
    ConfirmDialogModule,
    CustomFormsModule,
    AutoCompleteModule,
    TreeTableModule,
    OverlayModule,
    PipeModule,
  ],
  providers: [
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: UrlBackInterceptor, multi: true },
    { provide: ErrorHandler, useClass: PayerErrorHandler },
  ],
  exports: [
    TableComponent,
    ToolbarComponent,
    TabViewComponent,
    AvvisoCampiObbligatoriComponent,
    AvvisoCampiObbligatoriComponent,
  ],
})
export class CommonComponentModule {}
