import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from "rxjs";
import { flatMap } from "rxjs/operators";
import { EsitoEnum } from 'src/app/enums/esito.enum';
import { Bollettino } from 'src/app/features/pagamenti/model/Bollettino';
import { DettaglioTransazioneEsito } from 'src/app/features/pagamenti/model/DettaglioTransazioneEsito';
import { DettagliTransazione } from 'src/app/features/pagamenti/model/DettagliTransazione';
import { NuovoPagamentoService } from 'src/app/features/pagamenti/services/nuovo-pagamento.service';
import { MenuService } from 'src/app/services-old/menu.service';
import { Banner } from 'src/app/shared/model/banner.model';
import { BannerService } from 'src/app/shared/services/banner.service';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit {

  testoAccedi = 'Accedi';

  constructor(
    private router: Router,
    private nuovoPagamentoService: NuovoPagamentoService,
    private bannerService: BannerService,
    public menuService: MenuService
  ) {
    if (localStorage.getItem('loginDaAnonimo')) {
      this.nuovoPagamentoService.prezzoEvent.emit({value: null});
      let bollettini: Bollettino[] = [];
      for (var key in localStorage) {
        if (key.startsWith("boll-")) {
          let bollettino: Bollettino = JSON.parse(localStorage.getItem(key));
          bollettini.push(bollettino);
        }
      }
      if (bollettini.length > 0) {
        let observable: Observable<any> = this.nuovoPagamentoService.inserimentoBollettino(bollettini)
          .pipe(flatMap((result) => {
            let dettaglio: DettagliTransazione = new DettagliTransazione();
            result.forEach((value: DettaglioTransazioneEsito) => {
              if (value.esito !== EsitoEnum.OK && value.esito !== EsitoEnum.PENDING) {
                dettaglio.listaDettaglioTransazioneId.push(value.dettaglioTransazioneId);
              }
            });
            return this.nuovoPagamentoService.inserimentoCarrello(dettaglio);
          }));
        observable.subscribe(() => {
          nuovoPagamentoService.getCarrello().subscribe((value) => this.nuovoPagamentoService.prezzoEvent.emit({value: value.totale}));
          this.clearLocalStorage();
          localStorage.removeItem('loginDaAnonimo');
          this.router.navigateByUrl("/nuovoPagamento");
        });
      } else {
        localStorage.removeItem('loginDaAnonimo');
        if (localStorage.getItem("parziale") != null) {
          this.router.navigateByUrl("/nuovoPagamento");
        }
      }
    }

    if (localStorage.getItem('access_jwt')) {
      this.router.navigate(['/riservata']);
      return;
    }

  }

  ngOnInit(): void {
    const bannerObservable: Observable<Banner[]> = this.bannerService
      .letturaBanner(this.bannerService.timestamp, this.bannerService.attivo);
    bannerObservable.subscribe((banners) => {
      this.bannerService.bannerEvent.emit(banners);
    });
  }

  getLoginLink() {
    if (this.menuService.isUtenteAnonimo) {
      window.location.href = environment.bffBaseUrl + '/loginLepida.htm';
    } else {
      // NOTING TO DO
    }
  }

  private clearLocalStorage() {
    for (var key in localStorage) {
      if (key.startsWith("boll-")) {
        localStorage.removeItem(key);
      }
    }
  }
}
