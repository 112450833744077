import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { PipeModule } from "../pipe/pipe.module";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { LoginBarComponent } from "./components/login-bar/login-bar.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ErrorePage } from "./errors/pagina-errore/errore.page";
import { CookiePolicyPage } from "./pages/cookie-policy/cookie-policy.page";
import { HomePage } from "./pages/home/home.page";
import { ManutenzionePage } from "./pages/manutenzione/manutenzione.page";
import { PrivacyPage } from "./pages/privacy/privacy.page";

@NgModule({
  declarations:[
    // Pagine
    HomePage,
    ErrorePage,
    PrivacyPage,
    CookiePolicyPage,
    ManutenzionePage,
    
    HeaderComponent,
    FooterComponent,
    LoginBarComponent,
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipeModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoginBarComponent,
    SidebarComponent,
  ]
})
export class PayerCoreModule {}
