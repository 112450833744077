import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Comune } from '../../modules/main/model/Comune';
import { Provincia } from '../../modules/main/model/Provincia';
import { LivelloIntegrazione } from './livello-integrazione.service';
import { VersionService } from './version.service';
import { Funzione } from 'src/app/modules/main/model/Funzione';
import { map, retry, shareReplay } from 'rxjs/operators';

export enum CommonData {
  PROVICE = 'province',
  COMUNI = 'comuni',
  LIVELLI_INTEGRAZIONE = 'livelli-integrazione'
}

/**
 * Servizio per caricare e leggere dati comuni per l'applicazione.
 * I vari dati vengono salvati in localStorage e letti al bisogno.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalDataService {

  private province$ = new BehaviorSubject<Provincia[]>([]);
  private comuni$ = new BehaviorSubject<Comune[]>([]);
  private livelliIntegrazione$ = new BehaviorSubject<LivelloIntegrazione[]>([]);

  constructor(private http: HttpClient, private versionService: VersionService) {
    Object.values(CommonData).forEach(cd => {
      if (!localStorage.getItem(cd) || JSON.parse(localStorage.getItem(cd)).version !== this.versionService.getVersion()) {
        const url = environment.bffBaseUrl + '/' + cd;

        this.http.get(url, { withCredentials: true }).subscribe(
          (data) => {
            const d = {
              version: this.versionService.getVersion(),
              data: data
            };
            localStorage.setItem(cd, JSON.stringify(d));
            //console.log('Stored data {' + cd + "} from: " + url);
            this.getSubject(cd).next(data);
          }
        )
      } else {
        const d = JSON.parse(localStorage.getItem(cd));
        this.getSubject(cd).next(d.data);
      }
    });
  }

  private getSubject(type: CommonData): BehaviorSubject<any> {
    switch (type) {
      case CommonData.PROVICE:
        return this.province$;
      case CommonData.COMUNI:
        return this.comuni$;
      case CommonData.LIVELLI_INTEGRAZIONE:
        return this.livelliIntegrazione$;
    }
  }

  getData(type: CommonData): Observable<any> {
    return this.getSubject(type);
  }

  public readonly funzioniGestione$: Observable<Funzione[]> = this.http
    .get<Funzione[]>(`${environment.bffBaseUrl}/funzioni`)
    .pipe(retry(3))
    .pipe(
      map((funzioni: Funzione[]) => {
        return funzioni
          .filter((f: Funzione) => {
            return f.gruppo === "gestione";
          })
          .sort((a: Funzione, b: Funzione) => {
            return a.id - b.id;
          });
      })
    )
    .pipe(shareReplay(1));
}
