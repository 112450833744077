import { CampoTipoRendicontazione } from "src/app/features/gestisci-anagrafiche/gestisci-flussi-rendicontazione/model/CampoTipoRendicontazione";


export class CampoTipologiaServizio {
  uuid: string;
  id: number;
  titolo: string;
  tipologiaServizioId: number;
  obbligatorio: boolean = false;
  tipoCampoId: number;
  lunghezzaVariabile: boolean = false;
  lunghezza: number;
  campoFisso: boolean = false;
  disabilitato: boolean = false;
  nonVisibile: boolean = false;
  posizione: number;
  chiave: boolean = false;
  campoInput: boolean = false;
  tipologica: string;
  dipendeDa: DipendeDa;
  controlloLogicoId: string;
  campoDettaglioTransazione: string;
  campoTipoRendicontazione: CampoTipoRendicontazione;
  // listaCampoTipoRendicontazioneId: any[] = [];
}

interface DipendeDa {
  id: number;
  titolo: string;
  riferimentoPerId: number;
}
