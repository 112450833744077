import { NgModule } from "@angular/core";
import { ModaleFlussiRendicontazioneComponent } from "./raw/gestisci-flussi-rendicontazione/modale-flussi-rendicontazione/modale-flussi-rendicontazione.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ModaleFlussiRendicontazioneComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [ModaleFlussiRendicontazioneComponent],
})
export class ModaliGestisciFlussiRendicontazioneModule {}
