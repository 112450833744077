import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { getBannerType, LivelloBanner } from "../../enums/livelloBanner.enum";
import { BannerService } from "../../services-old/banner.service";
import { SpinnerOverlayService } from "../../shared/services/spinner-overlay.service";
import { Banner } from "../../shared/common/model/Banner";

@Injectable({
  providedIn: "root",
})
export class UrlBackInterceptor {
  urlRitorno: string;

  private readonly urlEscluse: string[] = [
    "verificaEsitoPagamento",
    "verificaEsitoPagamentoL1",
  ];

  constructor(
    private route: Router,
    private bannerService: BannerService,
    private inj: Injector
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const spinnerOverlayService = this.inj.get(SpinnerOverlayService);
    let subscription;
    if (
      this.urlEscluse.findIndex(
        (u) => u.indexOf(request.url) !== -1
      ) == -1
    ) {
      subscription = spinnerOverlayService.spinner$.subscribe();
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // set this.urlRitorno con header error response
        if (error.status === 400) {
          const banner: Banner = {
            titolo: "ATTENZIONE",
            testo: error.error.message,
            tipo: getBannerType(LivelloBanner.ERROR),
          };
          this.bannerService.bannerEvent.emit([banner]);
        } else if (error.status === 401) {
          this.route.navigateByUrl("/nonautorizzato");
        } else {
          console.error(error.message);
          this.route.navigateByUrl("/erroregenerico");
        }
        return throwError(error);
      }),
      finalize(() => {
        //console.log(request.url);
        if (
          this.urlEscluse.findIndex(
            (u) => u.indexOf(request.url) !== -1
          ) == -1
        ) {
          subscription.unsubscribe();
        }
      })
    );
  }
}
