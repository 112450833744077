import { Component, OnInit } from "@angular/core";
import { PayerPage } from "src/app/shared/common/abstracts/payer-page.abstract";

@Component({
  templateUrl: "./cookie-policy.page.html",
  styleUrls: ["./cookie-policy.page.scss"],
})
export class CookiePolicyPage extends PayerPage {
  constructor() {
    super();
  }
}
