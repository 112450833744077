import { map } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AsyncSubject, Observable } from 'rxjs';
import { finished } from 'stream';

@Injectable({
  providedIn: 'root'
})
export class AmministrativoService {

  mappaFunzioni = {};

  asyncAmministrativoSubject: AsyncSubject<any> = new AsyncSubject<any>();

  salvaCampoFormEvent: EventEmitter<any> = new EventEmitter<any>();

  salvaTipoPortaleEsternoEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  getFunzioneAmministratoreId(funzione: string): Observable<number> {
    const f = funzione.startsWith('/') ? funzione : '/' + funzione;

    return this.asyncAmministrativoSubject.pipe(map(finished => {
      return this.mappaFunzioni[f];
    }));
  }
}
