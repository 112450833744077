import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GruppoEnum } from '../../enums/gruppo.enum';
import { Funzione } from '../../modules/main/model/Funzione';

@Injectable({
  providedIn: 'root'
})
export class FunzioneService {

  private letturaFunzioniUrl = '/funzioni';

  constructor(private http: HttpClient) { }

  letturaFunzioni(gruppo?: GruppoEnum): Observable<Funzione[]> {
    return this.http.get(environment.bffBaseUrl + this.letturaFunzioniUrl)
      .pipe(map((res: Funzione[]) => {
        if (gruppo)
          return res.filter(f => f.gruppo === gruppo)

        return res;
      }));
  }

}
