<payer-table [data]="this.data" class="d-block">
    <ng-template #headers>
        <th class="searchable">
            Test ffffffffffffffffffffffffffff
        </th>
        <th class="search-asc">
            Test
        </th>
        <th class="search-desc">
            Test
        </th>
    </ng-template>
</payer-table>