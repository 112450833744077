import { Injectable, OnDestroy } from "@angular/core";
import { TipologicaSelectEnum } from "../../enums/tipologicaSelect.enum";
import { Comune } from "../../modules/main/model/Comune";
import { Provincia } from "../../modules/main/model/Provincia";
import { CommonData, CommonDataService } from "./common-data.service";
import { take } from "rxjs/operators"
import { Subscription } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToponomasticaService implements OnDestroy {
  private subs: Subscription[] = [];
  private province: Provincia[];
  private comuni: Comune[]

  constructor(dataService: CommonDataService) {
    dataService.getData(CommonData.PROVICE).subscribe(p => this.province = p);
    dataService.getData(CommonData.COMUNI).subscribe(c => this.comuni = c);
  }

  getProvince(): Provincia[] {
    return this.province;
  }

  getComuni(): Comune[] {
    return this.comuni;
  }

  get(toponomastica: TipologicaSelectEnum) {
    switch (toponomastica) {
      case TipologicaSelectEnum.PROVINCE:
        return this.province;
      case TipologicaSelectEnum.COMUNI:
        return this.comuni;
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
