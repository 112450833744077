import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface BreadcrumbInfo {
  label: string;
  overrideLink?: string;
}

interface Breadcrumb {
  label: string;
  link: string;
  active: boolean;
}

@Component({
  selector: 'payer-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs: Breadcrumb[] = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.breadcrumbs.push({
      label: 'Home',
      link: '',
      active: false
    })

    this.route.snapshot.pathFromRoot.forEach((p, i, route) => {
      if (p.data.breadcrumb) {
        const bcinfo: BreadcrumbInfo = p.data.breadcrumb;
        const bclink: string = this.breadcrumbs[i - 1].link + (String(p.url).length > 0) ? `/${p.url}` : '';
        
        this.breadcrumbs.push({
          label: bcinfo.label,
          link: bcinfo.overrideLink || bclink,
          active: i == route.length - 1
        })
      }
    })
  }

}
