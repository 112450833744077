import { NgModule } from "@angular/core";
import { ModaleCampoFormComponent } from "./raw/gestisci-tipologia-servizio/modale-campo-form/modale-campo-form.component";
import { ModaleAggiungiTipoCampoComponent } from "./raw/gestisci-tipologia-servizio/modale-campo-form/modale-aggiungi-tipo-campo/modale-aggiungi-tipo-campo.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ModaleCampoFormComponent, ModaleAggiungiTipoCampoComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [ModaleCampoFormComponent, ModaleAggiungiTipoCampoComponent],
})
export class ModaliGestisciTipologiaServizioModule {}
