import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DatePickerComponent, ECalendarValue } from 'ng2-date-picker';
import { map } from 'rxjs/operators';
import { OpzioneSelect } from 'src/app/modules/main/model/OpzioneSelect';
import { Banner } from 'src/app/shared/model/banner.model';
import { BannerService } from 'src/app/shared/services/banner.service';
import { getBannerType, LivelloBanner } from "../../../../../enums/livelloBanner.enum";
import { TipoCampoEnum } from '../../../../../enums/tipoCampo.enum';
import { ParametriRicercaPagamenti } from '../../../../../features/amministra-portale/gestisci-utenti/model/ParametriRicercaPagamenti';
import { IMieiPagamentiService } from '../../../../../services-old/i-miei-pagamenti.service';
import { FunzioneService } from '../../../../../shared/services/funzione.service';
import { OverlayService } from '../../../../../shared/services/overlay.service';
import { Utils } from "../../../../../utility/Utils";
import { DatiPagamento } from '../../../model/DatiPagamento';
import { ListaPagamentiFiltri } from '../../../model/ListaPagamentiFiltri';
import { NuovoPagamentoService } from '../../../services/nuovo-pagamento.service';


@Component({
  selector: 'app-filtri-i-miei-pagamenti',
  templateUrl: './filtri-i-miei-pagamenti.component.html',
  styleUrls: ['./filtri-i-miei-pagamenti.component.scss']
})
export class FiltriIMieiPagamentiComponent implements OnInit {

  listaLivelliTerritoriali: Array<OpzioneSelect> = [];
  listaEnti: Array<OpzioneSelect> = [];
  listaServizi: Array<OpzioneSelect> = [];

  isCalendarOpen = false;
  readonly minDateDDMMYY = '01/01/1900';
  readonly tipoData = ECalendarValue.String;

  filtroRicercaPagamenti: ParametriRicercaPagamenti;

  @Input()
  listaPagamento: Array<DatiPagamento> = new Array<DatiPagamento>();

  @Output()
  onChangeListaPagamenti: EventEmitter<ListaPagamentiFiltri> = new EventEmitter<ListaPagamentiFiltri>();

  constructor(private nuovoPagamentoService: NuovoPagamentoService,
              private funzioneService: FunzioneService, private iMieiPagamentiService: IMieiPagamentiService,
              private overlayService: OverlayService, private bannerService: BannerService) {
  }

  ngOnInit(): void {
    this.inizializzaFiltroRicercaPagamenti();

    // recupero dati select
    this.recuperaLivelloTerritoriale();
    this.recuperaEnti();
    this.recuperaFiltroServizi(null);
  }

  private inizializzaFiltroRicercaPagamenti() {
    this.filtroRicercaPagamenti = new ParametriRicercaPagamenti();
    this.filtroRicercaPagamenti.livelloTerritorialeId = null;
    this.filtroRicercaPagamenti.servizioId = null;
    this.filtroRicercaPagamenti.enteId = null;
    this.filtroRicercaPagamenti.numeroDocumento = null;
    this.filtroRicercaPagamenti.dataPagamentoDa = null;
    this.filtroRicercaPagamenti.dataPagamentoA = null;
  }

  recuperaLivelloTerritoriale(): void {
    this.nuovoPagamentoService.recuperaFiltroLivelloTerritoriale().pipe(map(livelliTerritoriali => {
      livelliTerritoriali.forEach(livello => {
        this.listaLivelliTerritoriali.push({
          value: livello.id,
          label: livello.nome
        });
      });
      this.listaLivelliTerritoriali = _.sortBy(this.listaLivelliTerritoriali, ['label']);
    })).subscribe();
  }

  recuperaEnti(idLivelloTerritoriale?: number): void {
    this.nuovoPagamentoService.recuperaFiltroEnti(idLivelloTerritoriale, null, null).pipe(map(enti => {
      enti.forEach(ente => {
        this.listaEnti.push({
          value: ente.id,
          label: ente.nome
        });
      });
      this.listaEnti = _.sortBy(this.listaEnti, ['label']);
    })).subscribe();
  }

  recuperaFiltroServizi(idEnte?: number): void {
    this.nuovoPagamentoService.recuperaFiltroServizi(idEnte).pipe(map(servizi => {
      servizi.forEach(servizio => {
        this.listaServizi.push({
          value: servizio.id,
          label: servizio.nome
        });
      });
      this.listaServizi = _.sortBy(this.listaServizi, ['label']);
    })).subscribe();
  }

  setPlaceholder(campo: NgModel, tipo: string): string {
    if (this.isCampoInvalido(campo)) {
      return 'campo non valido';
    } else {
      if (TipoCampoEnum.SELECT === tipo) {
        return 'seleziona un elemento dalla lista';
      } else if (TipoCampoEnum.INPUT_TESTUALE === tipo) {
        return 'inserisci testo';
      } else {
        return 'inserisci data';
      }
    }
  }

  isCampoInvalido(campo: NgModel) {
    return campo?.errors;
  }

  openDatepicker(datePickerComponent: DatePickerComponent): void {
    datePickerComponent.api.open();
    this.isCalendarOpen = !this.isCalendarOpen;
  }

  pulisciFiltri(filtroGestioneUtentiForm: NgForm): void {
    filtroGestioneUtentiForm.resetForm();
    this.inizializzaFiltroRicercaPagamenti();
    this.ricercaPagamenti(this.filtroRicercaPagamenti);
  }

  cercaPagamenti(form: NgForm): void {
    // inizia spinner
    if(!form.valid){
      const banner: Banner = {
        titolo: 'ERRORE',
        testo: 'Verificare parametri ricerca',
        tipo: getBannerType(LivelloBanner.ERROR)
      };
      this.bannerService.bannerEvent.emit([banner]);
    } else {
      Object.keys(form.value).forEach(key => {
        const value = form.value[key];
        if (value !== undefined) {
          this.filtroRicercaPagamenti[key] = value;
        } else {
          this.filtroRicercaPagamenti[key] = null;
        }
      });
      this.ricercaPagamenti(this.filtroRicercaPagamenti);
    }
  }

  ricercaPagamenti(filtri: ParametriRicercaPagamenti) {
    let filtriToBE: ParametriRicercaPagamenti = new ParametriRicercaPagamenti();
    filtriToBE.enteId = filtri.enteId;
    filtriToBE.livelloTerritorialeId = filtri.livelloTerritorialeId;
    filtriToBE.servizioId = filtri.servizioId;
    filtriToBE.numeroDocumento = filtri.numeroDocumento;
    filtriToBE.dataPagamentoDa = filtri.dataPagamentoDa ? moment(filtri.dataPagamentoDa, Utils.FORMAT_DATE_CALENDAR).format(Utils.FORMAT_LOCAL_DATE_TIME) : null;
    filtriToBE.dataPagamentoA = filtri.dataPagamentoA ? moment(filtri.dataPagamentoA, Utils.FORMAT_DATE_CALENDAR).format(Utils.FORMAT_LOCAL_DATE_TIME_TO) : null;
    this.iMieiPagamentiService.ricercaPagamenti(filtriToBE).pipe(map(listaPagamenti => {
      // Non invio la lista in caso di bad request
      if (listaPagamenti) {
        const listaPagamentiFiltri: ListaPagamentiFiltri = new ListaPagamentiFiltri();
        listaPagamentiFiltri.listaPagamenti = listaPagamenti;
        listaPagamentiFiltri.filtri = filtri;
        this.onChangeListaPagamenti.emit(listaPagamentiFiltri);
      }
    })).subscribe();
  }

  disabilitaBottone(filtroGestioneUtentiForm: NgForm, nomeBottone: string): boolean {
    const isAtLeastOneFieldValued = Object.keys(filtroGestioneUtentiForm.value).some(key => filtroGestioneUtentiForm.value[key]);
    if (nomeBottone === 'Pulisci') {
      return !isAtLeastOneFieldValued;
    } else {
      return !filtroGestioneUtentiForm.valid || !isAtLeastOneFieldValued;
    }
  }

  selezionaLivelloTerritoriale(): void {
    this.listaEnti = [];
    this.recuperaEnti(this.filtroRicercaPagamenti.livelloTerritorialeId);
    this.filtroRicercaPagamenti.enteId = null;
    this.selezionaEnte();
  }

  selezionaEnte(): void {
    this.listaServizi = [];
    this.recuperaFiltroServizi(this.filtroRicercaPagamenti?.enteId);
    this.filtroRicercaPagamenti.servizioId = null;
  }

}
