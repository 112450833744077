import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Banner } from 'src/app/shared/model/banner.model';
import { BannerService } from 'src/app/shared/services/banner.service';
import { getBannerType, LivelloBanner } from '../../enums/livelloBanner.enum';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {


  timestamp;
  attivo;
  banners: Banner[] = [];
  livello;
  classe: string = 'alert alert-dismissible fade show';

  BannerType = LivelloBanner;
  getBannerType = getBannerType;

  constructor(private bannerService: BannerService) {
  }

  ngOnInit(): void {
    this.bannerService.bannerEvent.subscribe((banners: Banner[]) => {
      const bannersTemp = banners.map(banner => {
        banner.dataSistema = moment().format('DD-MM-YYYY HH:mm:ss');
        this.classe = banner.tipo ? banner.tipo.classe : getBannerType(LivelloBanner.INFO).classe;
        banner.classe = this.classe;
        return banner;
      });
      this.banners = this.banners.concat(bannersTemp);
    });
  }

  onClick(banner: Banner) {
    this.banners = this.banners.filter(value => value !== banner);
  }

}
