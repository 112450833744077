import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TipoPortaleEsterno } from 'src/app/features/amministra-portale/configura-portali-esterni/model/portale-esterno.model';
import { LivelloBanner, getBannerType } from "../../enums/livelloBanner.enum";
import { DatiModaleTipoFlussoRendicontazione } from "../../features/gestisci-anagrafiche/gestisci-flussi-rendicontazione/raw/gestisci-flussi-rendicontazione/modale-flussi-rendicontazione/modale-flussi-rendicontazione.component";
import { DatiModaleCampo } from "../../features/gestisci-anagrafiche/gestisci-tipologia-servizio/raw/gestisci-tipologia-servizio/modale-campo-form/modale-campo-form.component";
import { DatiPagamento } from '../../features/pagamenti/model/DatiPagamento';
import { Banner } from "../model/banner.model";
import { BannerService } from "./banner.service";

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  constructor(private router: Router, private bannerService: BannerService) {
  }

  mostraModaleDettaglioPagamentoEvent: EventEmitter<DatiPagamento> = new EventEmitter<DatiPagamento>();
  mostraModaleCampoEvent: EventEmitter<DatiModaleCampo> = new EventEmitter<DatiModaleCampo>();
  mostraModaleAggiungiTipoCampoEvent: EventEmitter<string> = new EventEmitter<string>();
  mostraModaleTipoFlussoRendicontazione: EventEmitter<DatiModaleTipoFlussoRendicontazione> = new EventEmitter<DatiModaleTipoFlussoRendicontazione>();
  mostraModaleTipoCampoEvent: EventEmitter<number> = new EventEmitter<number>();
  mostraModaleTipoPortaleEsternoEvent: EventEmitter<TipoPortaleEsterno> = new EventEmitter<TipoPortaleEsterno>();

  gestisciErrore(): void {
    this.mostraModaleDettaglioPagamentoEvent.emit(null)
    const banner: Banner = {
      titolo: 'ATTENZIONE',
      testo: 'Il bollettino non è pagabile online.',
      tipo: getBannerType(LivelloBanner.ERROR)
    };
    this.bannerService.bannerEvent.emit([banner]);
  }
}
