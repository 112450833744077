import { Utils } from '../utility/Utils';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ParametriRicercaPagamenti} from '../features/amministra-portale/gestisci-utenti/model/ParametriRicercaPagamenti';
import {DettagliTransazione} from '../features/pagamenti/model/DettagliTransazione';
import {DatiPagamento} from '../features/pagamenti/model/DatiPagamento';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class IMieiPagamentiService {

  private readonly ricercaPagamentiUrl = '/pagamenti';
  private readonly eliminaBollettinoUrl = '/eliminaBollettino';
  private readonly inserimentoPagamentiUrl = '/inserimentoPagamentiCarrello';
  private readonly stampaAttestatiPagamentoUrl = '/stampaAttestatiPagamento';

  constructor(private readonly http: HttpClient) {
  }

  ricercaPagamenti(filtro: ParametriRicercaPagamenti): Observable<DatiPagamento[]> {
    let params = new HttpParams();
    if (filtro.livelloTerritorialeId) {
      params = params.set('livelloTerritorialeId', String(filtro.livelloTerritorialeId));
    }
    if (filtro.enteId) {
      params = params.set('enteId', String(filtro.enteId));
    }
    if (filtro.servizioId) {
      params = params.set('servizioId', String(filtro.servizioId));
    }
    if (filtro.numeroDocumento) {
      params = params.set('numeroDocumento', filtro.numeroDocumento);
    }
    if (filtro.dataPagamentoDa) {
      let dataPagamentoDa = filtro.dataPagamentoDa;
      if (dataPagamentoDa.length == 10) {
        dataPagamentoDa = moment(dataPagamentoDa, Utils.FORMAT_DATE_CALENDAR).format(Utils.FORMAT_LOCAL_DATE_TIME)
      }
      params = params.set('dataPagamentoDa', dataPagamentoDa);
    }
    if (filtro.dataPagamentoA) {
      let dataPagamentoA = filtro.dataPagamentoDa;
      if (dataPagamentoA.length == 10) {
        dataPagamentoA = moment(dataPagamentoA, Utils.FORMAT_DATE_CALENDAR).format(Utils.FORMAT_LOCAL_DATE_TIME)
      }
      params = params.set('dataPagamentoA', dataPagamentoA);
    }

    return this.http.get(environment.bffBaseUrl + this.ricercaPagamentiUrl, {
      params,
      withCredentials: true
    })
      .pipe(map((body: any) => {
        return body;
      }));
  }

  eliminaBollettino(value: DettagliTransazione, isCarrello?: boolean) {
    let params = new HttpParams();
    params = params.set('isCarrello', isCarrello ? isCarrello.toString() : "false");
    return this.http.post(environment.bffBaseUrl + this.eliminaBollettinoUrl, JSON.stringify(value),
      {withCredentials: true, params}).pipe(map((body: any) => {
        return body;
      }),
      catchError((err, caught) => {
        if (err.status == 401 || err.status == 400) {
          return of(null);
        } else {
           return of(null);
        }
      }));
  }

  stampaAttestatiPagamento(listaIdentificativi: number[]): Observable<string[]> {
    let params = new HttpParams();
    const listaIdentficativiString = listaIdentificativi.join(',');
    params = params.set('listaIdentificativi', listaIdentficativiString);
    return this.http.get(environment.bffBaseUrl + this.stampaAttestatiPagamentoUrl,
      {params, withCredentials: true}).pipe(map((body: string[]) => {
        return body;
      }),
      catchError((err, caught) => {
        if (err.status == 401 || err.status == 400) {
          return of(null);
        } else {
           return of(null);
        }
      }));
  }


  inserimentoPagamentiCarrello(pagamenti: DatiPagamento[]): Observable<any> {
    const url = environment.bffBaseUrl + this.inserimentoPagamentiUrl;
    return this.http.post(url , JSON.stringify(pagamenti),
      {withCredentials: true}).pipe(map((body: any) => body),
      catchError((err, caught) => {
        if (err.status == 401 || err.status == 400) {
          return of(null);
        } else {
           return of(null);
        }
      }));
  }

}
