import { Component } from "@angular/core";
import { PayerPage } from "src/app/shared/common/abstracts/payer-page.abstract";

@Component({
  templateUrl: "./manutenzione.page.html",
  styleUrls: ["./manutenzione.page.scss"],
})
export class ManutenzionePage extends PayerPage {
  constructor() {
    super();
  }
}
