import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CollapseDivComponent } from './collapse-div/collapse-div.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { PageTitleComponent } from './page-title/page-title.component';

@NgModule({
  declarations: [
    IconButtonComponent,
    CollapseDivComponent,
    PageTitleComponent,
    BreadcrumbComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgbCollapseModule,
  ],
  exports: [
    IconButtonComponent,
    CollapseDivComponent,
    PageTitleComponent,
    BreadcrumbComponent
  ]
})
export class PayerCommonModule { }
