import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TableTesterPage } from "./table-tester/table-tester.page";
import { TesterPage } from "./tester/tester.page";
import { UILibraryPage } from "./ui-library/ui-library.page";
import { PayerCommonModule } from "src/app/shared/modules/common/common.module";
import { PayerFormsModule } from "src/app/shared/modules/forms/payer-forms.module";
import { PayerTableModule } from "src/app/shared/modules/tables/payer-tables.module";

@NgModule({
  declarations: [
    TesterPage,
    UILibraryPage,
    TableTesterPage,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PayerCommonModule,
    PayerFormsModule,
    PayerTableModule
  ],
  exports: [
    TesterPage,
    UILibraryPage,
    TableTesterPage,
  ]
})
export class PayerUtilityPagesModule {}
